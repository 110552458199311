.about-container {
  width: 100%;
	height: 100%;
  font-family: 'Open Sans', sans-serif;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
	position: relative;
}

.about-slide-panel {
  width: 100%;
  padding-top: 6rem;
}

.about-text-panel {
  /* height: 100%; */
	width: 100%;
  /* width: 60%; */
  display: flex;
  justify-content: flex-start;
  align-items: center;
	flex-direction: column;
	flex-shrink: 0;
	/* min-height: 600px; */
}

.about-text-panel > p {
	font-size: clamp(1.2rem, 1.5vw, 2rem);
	font-weight: 300;
	max-width: 60%;
	text-align: center;
	/* line-height: 2.2rem; */
	/* max-height: 30%; */
	max-height: max-content;
	flex-shrink: 0;
	display: inline-block;
}

.about-text-panel > h1 {
	font-weight: 300;
	font-size: clamp(2.5rem, 3vw, 4rem);
	margin-top: 0;
	margin-bottom: 1rem;
	flex-shrink: 0;
	display: inline-block;
}

.about-text-panel > div {
	display: flex;
	flex-shrink: 0;
	display: inline-block;
}

 @keyframes scroll {
	 0% {
		 transform: translateX(0);
	}
	 100% {
		 transform: translateX(calc(-250px * 12));
	}
}
 .slider {
	 background: white;
   background-color: whitesmoke;
	 /* box-shadow: 0 10px 20px -5px rgba(0, 0, 0, .125); */
	 height: 300px;
	 margin: auto;
	 overflow: hidden;
	 position: relative;
	 width: 860px;
}
 .slider::before, .slider::after {
	 background: linear-gradient(to right, rgba(245, 245, 245, 1) 0%, rgba(245, 245, 245, 0) 100%);
	 content: "";
	 height: 400px;
	 position: absolute;
	 width: 250px;
	 z-index: 2;
}
 .slider::after {
	 right: 0;
	 top: 0;
	 transform: rotateZ(180deg);
}
 .slider::before {
	 left: 0;
	 top: 0;
}
 .slider .slide-track {
	 animation: scroll 40s linear infinite;
	 display: flex;
	 width: calc(250px * 24);
}
 .slider .slide {
	 height: 250px;
	 width: 960px;
}

.slide-image {
	padding: 1rem;
	width: 250px;
	height: auto;
}

.slide-image.css {
	width: auto;
	height: 250px;
	max-height: 250px;
}

/* .about-down { */
	/* position: absolute;
	bottom: 0;
	left: 50%;
	transform: translate(-50%, 0); */

	/* opacity: 0;
	transition: opacity 1s linear;
	margin-top: 4.7rem; */
/* } */

.about-down {
	position: absolute;
	bottom: 0;
	left: 50%;
	transform: translate(-50%, 0);
	opacity: 0;
	transition: opacity 1s linear;
	margin-top: 4.7rem;
}

.about-down:hover {
	cursor: pointer;
}

.about-down > svg {
	animation: pulse 2s infinite;
	animation-timing-function: ease-in-out;
	font-size: clamp(5rem, 7vw, 8rem);
}

.resume-link {
	margin-top: 2rem;
	display: inline-block;
	/* justify-content: space-between; */
	/* align-items: center; */
}

.resume-link > a {
	color: black;
	font-size: clamp(1.2rem, 1.5vw, 2rem);
}

.resume-link > a:visited {
	color: black;
}

.resume-link > svg {
	font-size: clamp(1.2rem, 1.5vw, 2rem);
	margin: 0 1rem;
}


@media screen and (max-width: 1280px) {
  .slider {
    width: 90%;
  }

  .slider::before, .slider::after {
    background: linear-gradient(to right, rgba(245, 245, 245, 1) 0%, rgba(245, 245, 245, 0) 30%);
 	}

	 .about-container {
		 justify-content: flex-start;
	 }

	.about-text-panel > p {
		max-width: 80%;
		/* line-height: 1.3rem; */
	}
}

@media screen and (max-width: 960px) {
	.about-down {
		margin-top: 1rem;
		bottom: 6rem;
	}

	.about-text-panel {
		width: 80%;
	}

	.about-text-panel > p {
		max-width: 90%;
	}
}

@media screen and (max-width: 600px) {
	@keyframes scroll {
		0% {transform: translateX(0)}
		100% {transform: translateX(calc(-175px * 12))}
 	}
 	.slider::before, .slider::after {
		width: 175px;
	}	
	.slider .slide-track {
		width: calc(175px * 24);
 	}
 	.slide-image {
		padding: 1rem;
		width: 175px;
		height: auto;
	}

	.slider {
		height: 200px;
	}

	.slide-image.css {
		width: auto;
		height: 175px;
		max-height: 175px;
	}

	.about-container {
		justify-content: flex-start;
	}

	.about-text-panel {
		width: 95%;
	}

	.about-text-panel > h1 {
		margin-top: 1rem;
		margin-bottom: 0;
	}
	.about-text-panel > p {
		/* line-height: 1.3rem; */
		max-width: 98%;
		flex-shrink: 0;
	}

	.about-slide-panel {
		padding-top: 4rem;
	}
}
