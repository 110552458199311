body, html {
  background-color: whitesmoke;
  -webkit-overflow-scrolling: touch;
}
body::-webkit-scrollbar {
  width: 0px;
  background: transparent;
}

button:focus {
  outline: none;
}

.app-container {
  height: 100vh;
  width: 100vw;
  scroll-behavior: smooth;
  transition-property: scroll;
  transition-duration: 2s;
  -webkit-overflow-scrolling: touch;
}

.container {
  scroll-snap-type: y mandatory;
  overflow: scroll;
  height: 100vh;
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
}

#page-one {
  scroll-snap-align: start;
  height: 100vh;
  width: 100vw;
  /* background-color: #80ced6; */
}

#page-two {
  scroll-snap-align: start;
  /* background-color: #d5f4e6; */
  height: 100vh;
  width: 100vw;
}

#page-three {
  scroll-snap-align: start;
  height: 100vh;
  width: 100vw;
  overflow-x: hidden;
  /* background-color: #80ced6; */
}

#page-four {
  scroll-snap-align: start;
  /* background-color: #d5f4e6; */
  height: 100vh;
  width: 100vw;
}

@media screen and (max-width: 600px) {
  .container {
    scroll-snap-type: none;
  }

  #page-one, #page-two, #page-three, #page-four {
    scroll-snap-align: none;
  }

  .home-down, .about-down, .projects-down {
    display: none;
  }
}

@media screen and (max-height: 700px) {
  .container {
    height: 100%;
    /* height: auto; */
    scroll-snap-type: none;
    scroll-behavior: none;
    -webkit-overflow-scrolling: none;
  }

  .home-down, .about-down, .projects-down {
    display: none;
  }

  #page-one, #page-two, #page-three, #page-four {
    scroll-snap-align: none;
    /* height: 100%; */
    height: auto;
    margin: 20% 0;
  }
}