nav {
  width: 100vw;
  height: 64px;
  position: fixed;
  z-index: 2;
  top: 0; 
  padding: 0 1em;
  display: flex;
  justify-content: space-between;
  /* transform: translate(0, -64px); */
  transition-property: all;
  -webkit-transition-property: all;
  transition-duration: 0.3s;
  -webkit-transition-duration: 0.3s;
  transition-timing-function: cubic-bezier(.82,1.78,.97,.99);
}

nav > div > h1 {
  font-family: var(--font);
  font-weight: 300;
}

nav > div >  h1 > a {
  text-decoration: none;
  color: black;
}

.nav-links {
  display: flex;
  overflow: hidden;
  /* background-color: pink; */
  align-items: flex-end;
  /* width: 0%; */
  width: 100%;
  position: relative;
  opacity: 0;
  right: -200px;
  /* z-index: 999; */
  transition-property: all;
  /* transition-delay: 0.5s; */
  transition-duration: 0.3s;
  /* transition-timing-function: cubic-bezier(.82,1.78,.97,.99); */
  transition-timing-function: cubic-bezier(.86,.64,.73,.93);
}

.nav-links > h2 {
  margin: 0 1em;
  margin-bottom: 0.3em;
}

.nav-links > h2 > a {
  text-decoration: none;
  font-family: var(--font);
  font-weight: 300;
  vertical-align: center;
  color: black;
}

.nav-links > h2 > a:visited {
  text-decoration: none;
  color: black;
}

.hamburger-links {
  display: flex;
  align-items: center;
  padding: 0 2rem;
}

.hamburger-box:active {
  outline: none;
}

@media screen and (max-width: 600px) {

  #nav-desktop-home {
    display: none;
  }

  .hamburger-links {
    padding: 0 1rem;
    padding-top: 1rem;
  }

  .nav-links {
    font-size: .7rem;
  }
}

@media screen and (min-width: 600px) {

  #nav-mobile-home-link {
    display: none;
  }
  #nav-mobile-home {
    display: none;
  }

}