.contact-container {
  height: 100%;
  width: 100%;
  /* margin-top: 20rem; */
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: 'Open Sans', sans-serif;
}

.contact-links {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.contact-container > h1{
  font-weight: 300;
  text-align: center;
  width: 95%;
  font-size: clamp(2.2rem, 6vw, 8rem);
  text-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
}

.contact-links > a > img {
  margin: 2rem;
  max-height: clamp(4rem, 8vw, 9rem);
  /* height: auto; */
  box-sizing: border-box;
  max-width: auto;
  /* max-width: 30%; */
  filter: drop-shadow(0 4px 4px rgba(0,0,0,0.24));
  transition: all .2s linear;
}

.contact-links > a > img:hover {
  cursor: pointer;
  transform: scale(1.1);
  filter: drop-shadow(0 5px 5px rgba(0,0,0,0.24));
}

.contact-up {
  position: absolute;
  bottom: 2rem;
  left: 50%;
  transform: translate(-50%, 0);
  opacity: 0;
  transition: opacity 1s linear;
}

#back-to-top {
  font-weight: 300;
  margin-bottom: 2rem;
  margin-top: 0;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 0);
  position: absolute;
  text-align: center;
  opacity: 0;
  transition: opacity 1s linear;
}

.contact-up:hover {
  cursor: pointer;
}

.contact-up > svg {
  animation: pulse 2s infinite;
  animation-timing-function: ease-in-out;
  font-size: clamp(5rem, 7vw, 8rem);
  text-align: center;
}

@media screen and (max-width: 960px) {
  .contact-links {
    margin-bottom: 20%;
  }
}

