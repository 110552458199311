#carousel {
  overflow-x: auto;
  white-space: nowrap;
  display: flex;
  scroll-behavior: smooth;
}

#carousel::-webkit-scrollbar {
  display: none;
}

#carousel.snap {
  scroll-snap-type: x mandatory;
  -webkit-overflow-scrolling: touch;
}

#carousel.snap > div {
  scroll-snap-align: center;
}

#carousel.snap {
  scroll-snap-type: mandatory;
  -ms-scroll-snap-type: mandatory;
  scroll-snap-points-x: repeat(100%);
  -ms-scroll-snap-points-x: repeat(100%);
}

#carousel {
  position: relative;
  width: 100%;
  height: 100%;
}

#carousel > div {
  min-width: 100%;
  min-height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: #fff;
  font-size: 20px;
}

#carousel-1 {
  background-color: #e34747;
}

#carousel-2 {
  background-color: #5ab92c;
}

#carousel-3 {
  background-color: #226de2;
}

.single-project-page {
  height: 100%;
  width: 100%;
  /* min-height: 600px; */
  display: flex;
}

.project-image {
  height: 100%;
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.project-image > img {
  max-width: 75%;
  height: auto;
  box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
}

.project-description {
  height: 100%;
  width: 50%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: black;
  font-family: 'Open Sans', sans-serif;
}

.project-description-container {
  display: flex;
  flex-direction: column;
  width: 80%;
  /* max-width: 80%; */
  white-space: normal;
  text-align: left;
}

.project-description-container > a {
  color: black;
}

.project-description-container > a:visited {
  color: black;
}

.project-description-container > h1 {
  font-weight: 300;
  font-family: 'Open Sans', sans-serif;
  font-size: clamp(3rem, 5vw, 6rem);
  text-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
  margin-bottom: 2rem;
}

.project-description-container > p {
  display: inline-block;
  word-wrap: break-word;
  object-fit: contain;
  font-size: clamp(1rem, 1.3vw, 3rem);
  margin: 0 .53rem;
  font-weight: 300;
}

.projects-container {
  height: 100%;
  position: relative;
}

.project-left-arrow {
  position: absolute;
  top: 50%;
  left: 0;
  opacity: 1;
  transition: opacity .5s cubic-bezier(.79,.04,.84,1.22);
}

.project-right-arrow {
  position: absolute;
  top: 50%;
  right: 0;
  opacity: 1;
  transition: opacity .5s cubic-bezier(.79,.04,.84,1.22);
}

.project-right-arrow:hover, .project-left-arrow:hover {
  cursor: pointer;
}

.project-left-arrow.hidden, .project-right-arrow.hidden {
  opacity: 0;
}

.project-left-arrow.hidden:hover, .project-right-arrow.hidden:hover {
  cursor: auto;
}

.project-right-arrow > svg {
  transform: rotate(-90deg);
  font-size: clamp(4rem, 6vw, 7rem);
}

.project-left-arrow > svg {
  transform: rotate(90deg);
  font-size: clamp(3.5rem, 5.5vw, 6rem);
}

.projects-down {
  position: absolute;
  bottom: 0;
  left: 50%;
  opacity: 0;
  transform: translate(-50%, 0);
  transition: opacity 1s linear;
}

.projects-down:hover {
  cursor: pointer;
}

.projects-down > svg {
  animation: pulse 2s infinite;
  animation-timing-function: ease-in-out;
  font-size: clamp(5rem, 7vw, 8rem);
}

@media screen and (max-width: 960px) {
  .single-project-page {
    flex-direction: column;
  }

  .project-image {
    width: 100%;
    height: 45%;
    justify-content: center;
    align-items: flex-end;
  }

  .project-image > img {
    max-width: 90%;
    max-height: 75%;
  }

  .project-description {
    width: 100%;
    height: 55%;
    justify-content: center;
    align-items: flex-start;
  }

  .project-description-container {
    width: 100vw;
    justify-content: center;
    align-items: center;
  }

 .project-description-container > h1 {
    text-align: center;
  }
  .project-description-container > p {
    width: 90%;
    text-align: center;
    margin: 0;
  }

  .projects-down {
    bottom: 6rem;
  }
}