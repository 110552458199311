a:focus {
  outline:0;
}

.home-container {
  height: 100%;
  width: 100%;
  display: flex;
  font-family: 'Open Sans', sans-serif;
  position: relative;
}

.home-image-panel {
  height: 100%;
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.home-image-panel > img {
  max-height: 0%;
  max-width: 0%;
  opacity: 0;
  animation-name: image;
  animation-duration: .5s;
  animation-fill-mode: forwards;
  animation-timing-function: cubic-bezier(1,.74,.54,1.31);
  border-radius: 50%;
  box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
}


@keyframes image {
  0% {max-height: 0%; max-width: 0%; opacity: 0}
  100% {max-height: 50%; max-width: 50%; opacity: 1}
}

.home-text-panel {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.home-text-main {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}

.home-text-main > h1 {
  font-weight: 300;
  font-size: clamp(4rem, 6vw, 8rem);
  margin: 0;
  text-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
}

.home-text-secondary > h1 {
  font-weight: 300;
  font-size: clamp(1.3rem, 2vw, 3rem);
  text-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
}

#hi {
  opacity: 0;
  animation-name: hi;
  animation-delay: 1s;
  animation-duration: .5s;
  animation-fill-mode: forwards;
  animation-timing-function: cubic-bezier(.79,.04,.84,1.22);
}

#name {
  opacity: 0;
  animation-name: name;
  animation-fill-mode: forwards;
  animation-delay: 1.5s;
  animation-duration: .5s;
  animation-timing-function: cubic-bezier(.79,.04,.84,1.22);
}

.home-text-secondary {
  width: 0%;
  opacity: 0;
  display: inline-block;
  white-space: nowrap;
  animation-name: subtext;
  animation-delay: 2s;
  animation-duration: .5s;
  animation-fill-mode: forwards;
  animation-timing-function: cubic-bezier(.79,.04,.84,1.22);
  overflow: hidden;
  margin: 0 .53rem;
}

.home-down {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 0);
  opacity: 0;
  transition-delay: 4s;
  transition-property: opacity;
  transition-duration: 1s;
  transition-timing-function: linear;
}

.home-down:hover {
  cursor: pointer;
}

.home-down > svg {
  animation: pulse 2s infinite;
  animation-timing-function: ease-in-out;
  font-size: clamp(5rem, 7vw, 8rem);
}

@keyframes pulse {
  0% {transform: scale(0.95)}
  50% {transform: scale(1.1)}
  100% {transform: scale(0.95)}
}

@keyframes hi {
  0% {opacity: 0}
  100% {opacity: 1}
}

@keyframes name {
  0% {opacity: 0}
  100% {opacity: 1}
}

@keyframes subtext {
  0% {opacity: 0; width: 0%}
  100% {opacity: 1; width:100%}
}

@media screen and (max-width: 960px) {
  .home-container {
    flex-direction: column;
  }
  .home-image-panel {
    height: 50%;
    width: 100%;
    padding-top: 3rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  /* .home-text-panel {
    height: 30%;
  } */

  .home-text-main {
    justify-content: center;
  }

  .home-down {
    bottom: 6rem;
  }

  .home-text-secondary {
    left: 50%;
    /* transform: translate(0, 50%); */
  }

  .home-text-secondary {
    text-align: center;
    animation-timing-function: cubic-bezier(.78,.03,.89,.98);
    margin: 0;
  }

  @keyframes image {
    0% {max-height: 0%; max-width: 0%; opacity: 0;}
    100% {max-height: 75%; max-width: 75%; opacity: 1;}
  }
}